import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home.vue"),
    children: [
      {
        path: "/",
        name: "overview",
        component: () => import("@/views/overview/index.vue"),
      },
      {
        path: "personal",
        name: "personal",
        component: () => import("@/views/personal/index.vue"),
      },
      {
        path: "workflow/process/procedure",
        name: "procedure",
        component: () => import("@/views/procedure/index.vue"),
      },
      {
        path: "workflow/process",
        name: "process",
        component: () => import("@/views/process/index.vue"),
      },
      {
        path: "workflow",
        name: "workflow",
        component: () => import("@/views/workflow/index.vue"),
      },
      {
        path: "workflow/detail",
        name: "workflow-detail",
        component: () => import("@/views/workflow/workflow.vue"),
      },
      {
        path: "workflow/detail/procedure/edit",
        name: "workflow-procedure-edit",
        component: () => import("@/views/procedure/edit.vue"),
      },
      {
        path: "user",
        name: "user",
        component: () => import("@/views/user/index.vue"),
      },
      {
        path: "product",
        name: "product",
        component: () => import("@/views/product/index.vue"),
      },
      {
        path: "productLine",
        name: "productLine",
        component: () => import("@/views/productLine/index.vue"),
      },
      {
        path: "workPlace",
        name: "workPlace",
        component: () => import("@/views/workPlace/index.vue"),
      },
      {
        path: "opLog",
        name: "opLog",
        component: () => import("@/views/opLog/index.vue"),
      },
      {
        path: "order",
        name: "order",
        component: () => import("@/views/order/index.vue"),
      },
      {
        path: "order/detail/:id",
        name: "order-detail",
        component: () => import("@/views/order/order-detail.vue"),
      },
      {
        path: "sn",
        name: "sn",
        component: () => import("@/views/sn/index.vue"),
      },
      {
        path: "device",
        name: "device",
        component: () => import("@/views/device/index.vue"),
      },
      {
        path: "mac",
        name: "mac",
        component: () => import("@/views/mac/index.vue"),
      },
      {
        path: "macRequisition",
        name: "macRequisition",
        component: () => import("@/views/macRequisition/index.vue"),
      },
      {
        path: "gweui",
        name: "gweui",
        component: () => import("@/views/gweui/index.vue"),
      },
      {
        path: "factory",
        name: "factory",
        component: () => import("@/views/factory/index.vue"),
      },
      {
        path: "factory/:id",
        name: "factory-detail",
        component: () => import("@/views/factory/factory.vue"),
      },
      {
        path: "log",
        name: "log",
        component: () => import("@/views/log/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
