import Vue from "vue";
import ElementUI from "element-ui";
// 同时引入该主题可以避免 描述列表组件 样式错乱
import "element-ui/lib/theme-chalk/index.css";
import "enn-elementui/theme/index.css";
import less from "less";
import EnnChart from "enn-chart";
import { getUrlParam } from "@/utils";
import { getToken, setToken } from "@/utils/info";
import router from "@/router";
import store from "@/store";
import App from "./App.vue";

Vue.config.productionTip = false;

Vue.use(less);
Vue.use(ElementUI);
Vue.use(EnnChart);

// 进入页面后从 url 获取 token，有的话进行保存
let token = getUrlParam("token") || "";
if (token && token !== "undefined" && token !== "null") {
  setToken(token);
  window.location.href = window.location.href.replace(/token=([\d\w]+)/, "");
}

token = getToken();
if (token) {
  setToken(token);
}

new Vue({
  router,
  store,
  components: { App },
  render: (h) => h(App),
}).$mount("#app");
