import store from "@/store";
import { setAxiosToken } from "@/service/axios";

export const TOKEN = "token";
export const USER = "user";

/**
 * 保存 & 设置 token
 * @param {string} token
 */
export function setToken(token = "") {
  setAxiosToken(token || "");
  localStorage.setItem(TOKEN, token || "");
  store.commit("setToken", token || "");
}

/**
 * 获取 token
 * @returns {string} token
 */
export function getToken() {
  if (store.state && store.state[TOKEN]) {
    return store.state[TOKEN];
  }
  return localStorage.getItem(TOKEN);
}

/**
 * 保存用户信息
 * @param {Object} user
 */
export function setUser(user = {}) {
  if (Object.prototype.toString.call(user) !== "[object Object]") {
    console.error(new Error("用户信息需要为对象格式"));
    return;
  }
  localStorage.setItem(USER, JSON.stringify(user || {}));
  store.commit("setUser", user || {});
}

/**
 * 获取用户信息
 * @returns {Object} user
 */
export function getUser() {

  if (store.state && store.state[USER] && Object.keys(store.state[USER]).length > 0) {
    return store.state[USER];
  }
  const userStr = localStorage.getItem(USER);
  if (userStr === "null" || userStr === "undefined") {
    return {};
  }
  return JSON.parse(localStorage.getItem(USER) || "{}");
}
