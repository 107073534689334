import axios from "axios";
import router from "@/router";
import { setToken, getToken } from "@/utils/info";
import { apiUrl, apiSync } from '../api/api';
import { Message } from 'element-ui';
axios.defaults.baseURL = apiUrl;
axios.interceptors.response.use(
  (response) => {
    const { data } = response;
    if ([4001004, 4001006, 4001007].includes(data.code)) {
      return setTimeout(() => {
        // 如果 token 已经失效，清空本地 token
        setToken();
        router.push({ name: "login", params: { message: data.message } });
      }, 500);
    } else if (data.code !== 0) {
      Message({
        type: "error",
        message: data.message,
      });
      return Promise.resolve(response);
    }
    return Promise.resolve(response);
  },
  (error) => {
    console.log('error', error);
    // TODO: 按需修改判断值
    if (error.response.status === 403) {
      return setTimeout(() => {
        // 如果 token 已经失效，清空本地 token
        setToken();
        router.push({ name: "login", params: { message: "token 过期，请重新登录" } });
      }, 500);
    }
    return Promise.reject(error);
  }
);

export default axios;

export function setAxiosToken(token) {
  // axios.defaults.headers.common.token = token;
  axios.defaults.headers.authorization = token;
}

