<template>
  <router-view />
</template>

<style>
body {
  margin: 0;
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi,
    sans-serif;
}
.page-box .grid-card {
  margin-top: 15px;
}

.main,
.page-box {
  background-color: #f0f2f5;
}

.el-main.main-box {
  position: absolute;
  top: 60px;
  left: 200px;
  right: 0;
  bottom: 0;
  overflow: scroll;
}
/* 隐藏滚动条槽位 */
.el-main.main-box::-webkit-scrollbar {
  width: 0;
}
.page-box .grid-card {
  margin-top: 16px;
}
</style>

<script>
export default {
  components: {}
};
</script>